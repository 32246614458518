export default {
    ENTERPRISE: 'enterprise',
    ECOSYSTEM: 'ecosystem',
    STARTUP: 'startup',
}

export const licenseTypes = {
    'free': 'Free',
    'paid': 'Paid',
    'test': 'Test',
    'pilot': 'Pilot',
    'trial': 'Trial',
    'event_limited': 'Event Limited',
}

export const ACCOUNT_TYPE_PREMIUM = 'premium';
export const ACCOUNT_TYPE_PREMIUM_TEASER = 'premiumTeaser';
export const ACCOUNT_TYPE_EVENT_LIMITED = 'event_limited';
