import axios from '@/axios';

const RESOURCE_NAME = '/search';

export default {
    getList(data) {
        return axios.post(`${RESOURCE_NAME}/get-list`, data)
    },

    getRelatedKeywords(term) {
        return axios.get(`${RESOURCE_NAME}/get-related-words?term=${term}`)
    },

    getSimilar(data) {
        return axios.post(`${RESOURCE_NAME}/get-similar`, data)
    },

    suggest(term) {
        return axios.get(`${RESOURCE_NAME}/suggest?term=${term}`)
    },

    getAIQueryParams(term) {
        return axios.get(`${RESOURCE_NAME}/get-ai-query-params?text=${term}`);
    },
}
