<template>
    <div>
        <b-modal v-if="startups.length" :id="modalId" modal-class="modal-similar-startups modal-w-xxl" title="Similar startups" @hide="onCloseSimilarStartupsModal">
            <table class="main-table">
                <tr>
                    <th></th>
                    <th>Company</th>
                    <th>Match Level</th>
                    <th>Categories</th>
                </tr>

                <tbody>
                    <SimilarStartup v-for="(startup, index) in startups"
                                    :key="index"
                                    :startup="startup"
                                    :trackersList="trackersList"
                                    :selectedStartups.sync="selectedStartups"
                                    @updateSelection="updateSelection"
                                    :selectedTrackerId="similarToTracker ? similarToId : null"
                                    v-on="$listeners"
                    />
                </tbody>
            </table>
            
            <template #modal-footer>
                <ul class="modal-similar-startups__footer">
                    <li>{{selectedStartups.length}} startups selected</li>
                    <li>
                        <b-button variant="primary" size="md" @click="selectAll" class="button-select-all">
                            Select All
                        </b-button>
                    </li>
                    <li>
                        <b-button variant="primary" size="md" @click="deselectAll" class="button-deselect"
                                  :disabled="!selectedStartups.length">
                            Deselect All
                        </b-button>
                    </li>
                    <li>
                        <b-button variant="primary" size="md" class="button-track"
                                  v-on="similarToTracker ? { click: addToTracker } : { click: openAddToTrackers }"
                                  :disabled="!selectedStartups.length">
                            Add To Tracker
                        </b-button>
                    </li>
                </ul>
            </template>
            
        </b-modal>
        <b-modal v-else :id="modalId" modal-class="modal-w-md" hide-footer title="Similar startups">
            <div class="p-4 text-center">
                <p class="no-similar">No similar startups were found.</p>
                <b-button class="button" @click="$bvModal.hide('modal-similar-startups')">Close</b-button>
            </div>
        </b-modal>

        <AddToTrackers
            v-if="!similarToTracker"
            ref="addSimilarStartupsToTrackers"
            modalId="add-similar-startups-to-trackers-popup"
            :trackers-list="trackersList"
            @trackersSelected="addToTrackers"
        />
    </div>
</template>

<script>
    import search from "@/services/search.service"
    // import trackers from "@/services/trackers.service"
    import SimilarStartup from "@/components/startups/SimilarStartup.vue"
    import AddToTrackers from "@/components/manage/SelectTrackers.vue"
    import trackersCompanies from "@/services/trackers-companies.service"
    import {mapActions, mapGetters} from "vuex";

    export default {
        components: {
            SimilarStartup,
            AddToTrackers
        },
        data() {
            return {
                modalId: "modal-similar-startups",

                similarToId: null,
                similarToTracker: false,

                startups: [],
                // trackersList: [],
                selectedStartups: [],
            }
        },
        computed: {
            ...mapGetters(["trackersList"]),
        },
        mounted() {
            // this.$store.watch(state => {
            //     if (state.needUpdateTrackers) {
            //         this.getTrackersList()
            //     }
            // })
            //
            // this.getTrackersList()

            this.fetchTrackers()
        },
        methods: {
            ...mapActions(["fetchTrackers"]),
            open(similarToId, forTracker = false) {
                this.similarToId = similarToId
                this.similarToTracker = forTracker

                this.getSimilarStartups(similarToId, true, forTracker)
            },
            getSimilarStartups(similarToId, showModal = false, forTracker = false) {
                let requestData = {searchType: 'startups'};
                if (forTracker) {
                    requestData.trackerId = similarToId;
                } else {
                    requestData.companyId = similarToId;
                }

                search.getSimilar(requestData).then(response => {
                    this.startups = response.data

                    if (showModal) {
                        this.$bvModal.show(this.modalId)
                    }
                })
            },
            // getTrackersList() {
            //     trackers.getListForStartupAdd().then(response => {
            //         this.trackersList = response.data
            //     })
            // },
            openAddToTrackers() {
                this.$refs.addSimilarStartupsToTrackers.open()
            },
            addToTrackers(addedTrackers) {
                let trackersIds = addedTrackers.map(item => item.id)
                let startups = this.selectedStartups
                trackersCompanies.addCompanies(trackersIds, startups).then(() => {
                    this.deselectAll()
                    this.getSimilarStartups(this.similarToId)
                })
            },
            addToTracker() {
                let addedTrackers = [this.similarToId]
                let startups = this.selectedStartups

                trackersCompanies.addCompanies(addedTrackers, startups).then(() => {
                    this.$bvModal.hide(this.modalId)
                    this.$emit('startupsAdded')
                })
            },
            updateSelection(startup) {
                if (startup.selected) {
                    this.selectedStartups.push(startup.startupId)
                } else {
                    const index = this.selectedStartups.indexOf(startup.startupId)
                    if (index > -1) {
                        this.selectedStartups.splice(index, 1)
                    }
                }
            },
            selectAll() {
                this.selectedStartups = []
                this.startups.forEach((startup) => {
                    this.selectedStartups.push(startup.id)
                })
            },
            deselectAll() {
                this.selectedStartups = []
            },
            onCloseSimilarStartupsModal() {
                this.deselectAll();
            }
        }
    }
</script>

<style scoped>

</style>
