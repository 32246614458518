export default {
    methods: {
        processItem(item) {
            if (this.$store.state.user.event_ecosystem_name) {
                return item.replace('{ecosystem}', this.$store.state.user.event_ecosystem_name)
            }

            return item;
        }
    }
}
