<template>
    <b-modal title="Manage Columns" id="manage-columns" modal-class="modal-w-lg">

        <div class="content-switcher" v-if="canEditDefaultColumns">
            <span>Personal settings</span>
            <div class="content-switcher__slider">
                <input type="checkbox" id="content-switcher__slider" v-model="defaultSettings">
                <label for="content-switcher__slider"></label>
            </div>
            <span>Default Settings</span>
        </div>
        
        <p>Select the columns you want to display on the table and rearrange their order.</p>

        <h5 class="heading5 heading5--secondary">About</h5>

        <draggable v-model="columns.info" class="list-drag-n-drop">
            <div v-for="(column, index) in columns.info" :key="column.id" v-show="column.id != 1" class="list-drag-n-drop__item">
                <span class="input-checkbox">
                    <input :id="'info' + column.id" v-model="columns.info[index].checked" type="checkbox">
                    <label :for="'info' + column.id">{{ column.label }}</label>
                    
                    <div class="hint-secondary" v-if="columns.info[index].info" v-b-popover.hover.top="{ customClass: 'hint-secondary-popover', content: columns.info[index].info }"></div>
                </span>
            </div>
        </draggable>

        <template v-if="typeof columns.review !== 'undefined'">
            <h5 class="heading5 heading5--secondary">Review</h5>

            <draggable v-model="columns.question" class="list-drag-n-drop">
                <div v-for="(column, index) in columns.review" :key="column.id" class="list-drag-n-drop__item">
                    <span class="input-checkbox">
                        <input :id="'review' + column.id" v-model="columns.review[index].checked" type="checkbox">
                        <label :for="'review' + column.id">{{ column.label }}</label>
                    </span>
                </div>
            </draggable>
        </template>

        <template v-if="typeof columns.custom !== 'undefined'">
            <h5 class="heading5 heading5--secondary">Your Custom Fields</h5>

            <draggable v-model="columns.custom" class="list-drag-n-drop">
                <div v-for="(column, index) in columns.custom" :key="column.id" class="list-drag-n-drop__item">
                    <span class="input-checkbox">
                        <input :id="'custom' + column.id" v-model="columns.custom[index].checked" type="checkbox">
                        <label :for="'custom' + column.id">{{ column.label }}</label>
                    </span>
                </div>
            </draggable>
        </template>

        <template v-if="typeof columns.diligence !== 'undefined'">
            <h5 class="heading5 heading5--secondary">Startup Diligence</h5>

            <draggable v-model="columns.diligence" class="list-drag-n-drop">
                <div v-for="(column, index) in columns.diligence" :key="column.id" class="list-drag-n-drop__item">
                    <span class="input-checkbox">
                        <input :id="'diligence' + column.id" v-model="columns.diligence[index].checked" type="checkbox">
                        <label :for="'diligence' + column.id">{{ column.label }}</label>
                    </span>
                </div>
            </draggable>
        </template>

        <template v-if="typeof columns.question !== 'undefined'">
            <h5 class="heading5 heading5--secondary"><template v-if="trackerType === 'public'">Tracker</template><template v-else>Startup</template> Questions</h5>

            <draggable v-model="columns.question" class="list-drag-n-drop">
                <div v-for="(column, index) in columns.question" :key="column.id" class="list-drag-n-drop__item">
                    <span class="input-checkbox">
                        <input :id="'questions' + column.id" v-model="columns.question[index].checked" type="checkbox">
                        <label :for="'questions' + column.id">{{ column.label }}</label>
                    </span>
                </div>
            </draggable>
        </template>

        <template v-if="tableType === 'tracker-startups'">
            <h5 class="heading5 heading5--secondary">Profile Fields</h5>

            <draggable v-if="typeof columns.profile !== 'undefined'" v-model="columns.profile" class="list-drag-n-drop">
                <div v-for="(column, index) in columns.profile" :key="column.id" class="list-drag-n-drop__item">
                <span class="input-checkbox">
                    <input :id="'diligence' + column.id" v-model="columns.profile[index].checked" type="checkbox">
                    <label :for="'diligence' + column.id">{{ column.label }}</label>
                </span>
                </div>
            </draggable>

            <p class="modal-text">Profile fields display on the startup page and can be edited from your tracker or the startup's profile</p>

            <div class="accordion">
                <header class="accordion__header" v-b-toggle:profile-fields>
                    <a class="accordion__title link">Add More Fields</a>
                </header>

                <b-collapse accordion="profile-fields" id="profile-fields">
                    <div class="list-drag-n-drop">
                        <div v-for="(field, index) in profileFields" :key="index" class="list-drag-n-drop__item">
                            <span class="input-checkbox">
                                <input :id="'custom-field' + field.id" type="checkbox" v-model="profileFields[index].checked">
                                <label :for="'custom-field' + field.id">{{ field.label }}</label>
                            </span>
                        </div>
                    </div>
                </b-collapse>
            </div>
        </template>
        
        <template #modal-footer>
          <b-button variant="primary" size="lg" @click="saveColumns">Save</b-button>
        </template>
    </b-modal>
</template>

<script>

import user from '@/services/user.service'
import trackers from '@/services/trackers.service'
import customFields from '@/services/custom-fields.service'
import draggable from "vuedraggable";

export default {
    components: {
        draggable,
    },

    props: {
        tableType: String,

        trackerId: {
            type: Number,
            default: 0,
        },

        trackerType: String,
    },

    data() {
        return {
            columns: {},
            profileFields: [],
            watchProfileFields: false,
            watchColumnsProfileFields: true,
            canEditDefaultColumns: false,
            defaultSettings: false,
        }
    },

    watch: {
        tableType() {
            this.getColumns()
        },

        profileFields: {
            deep: true,
            handler() {
                if (this.watchProfileFields) {
                    this.columns.profile = []
                    this.profileFields.forEach(item => {
                        if (item.checked) {
                            this.columns.profile.push({
                                id: item.id,
                                label: item.label,
                                checked: item.checked,
                            })
                        }
                    })
                }
            }
        },

        'columns.profile': {
            deep: true,
            handler() {
                if (this.watchColumnsProfileFields) {
                    this.watchColumnsProfileFields = false

                    if (typeof this.columns.profile !== 'undefined') {
                        this.columns.profile.forEach((item, index) => {
                            if (!item.checked) {
                                let profileIndex = this.arrayHelper.findIndexByColumnValue(this.profileFields, 'id', item.id)
                                this.profileFields[profileIndex].checked = false
                                this.columns.profile.slice(index, 1)
                            }
                        })
                    }

                    this.$nextTick(() => {
                        this.watchColumnsProfileFields = true
                    })
                }
            }
        },

        defaultSettings() {
            this.getColumns()
        },
    },

    computed: {
        useType() {
            if (this.defaultSettings) {
                return 'default'
            }
            else {
                return 'personal'
            }
        },
    },

    methods: {
        open() {
            this.defaultSettings = false
            this.getColumns()
            this.$bvModal.show('manage-columns')
        },

        getColumns() {
            this.watchProfileFields = false

            if (this.trackerId) {
                trackers.getUserPermissions(this.trackerId).then(response => {
                    let userPermissions = response.data

                    if (userPermissions.indexOf('tracker-manage-default-columns') >= 0) {
                        this.canEditDefaultColumns = true
                    }
                })
            }

            user.getColumnsForManage(this.tableType, this.useType, this.trackerId).then(response => {
                this.columns = response.data

                if (this.tableType === 'tracker-startups') {

                    if (typeof this.columns.profile === "undefined") {
                        this.columns.profile = []
                    }

                    customFields.getFields('company_diligence', 1).then(response => {
                        this.profileFields = []

                        for (let i in response.data) {
                            this.profileFields.push({
                                id: response.data[i].id,
                                label: response.data[i].name,
                                checked: this.columns.profile.some(item => item.id === response.data[i].id)
                            })
                        }

                        this.$nextTick(() => {
                            this.watchProfileFields = true
                        })
                    })
                }
            })
        },

        saveColumns() {
            this.$bvModal.hide('manage-columns')

            user.updateColumns(this.tableType, this.useType, this.trackerId, this.columns).then(() => {
                if (this.useType != "default") {
                    this.$emit('changeColumns')
                }
            })
        },
    }
}
</script>

<style scoped>

</style>
