const equation = {
        data() {
            return {
                query : {
                    'rules': [],
                },

                customFieldsRules: []
            }
        },
        methods: {
            currentRuleLevel(rule, inGroup) {
                var index = {}
                this.query.rules.forEach(function (item, i) {
                    if (inGroup) {
                        if (item === rule) {
                            index = {'level_1': i, count: -1};
                            return;
                        }
                    } else {
                        if (typeof item.id !== 'undefined') {
                            if (item.id == rule.id) {
                                index = {'level_1': i, 'count': 1};
                                return;
                            }
                        } else {
                            if (typeof item.condition !== 'undefined') {
                                item.rules.forEach(function (elem, j) {
                                    if (typeof elem.id !== 'undefined') {
                                        if (elem.id == rule.id) {
                                            index = {'level_1': i, 'level_2': j, 'count': 2};
                                            return;
                                        }
                                    }
                                });
                            }
                        }
                    }
                });

                return index;
            },

            deleteRule(rule, level) {
                if (rule.name === "ecosystems" && this.$store.getters.isFreeEnterprise) {
                    this.showUpgradeModal();
                    return;
                }

                if (!level) {
                    level = this.currentRuleLevel(rule);
                }

                // Level 1
                if (level['count'] === 1) {
                    this.query.rules.splice(level['level_1'], 1);
                }

                // Level 2
                if (level['count'] === 2) {
                    var subRulesCount = this.query.rules[level['level_1']].rules.length;

                    if (subRulesCount > 2) {
                        this.query.rules[level['level_1']].rules.splice(level['level_2'], 1);
                    } else {
                        this.query.rules[level['level_1']].rules.splice(level['level_2'], 1);
                        var remainingRule = this.query.rules[level['level_1']].rules[0];
                        this.query.rules.splice(level['level_1'], 1, remainingRule);
                    }
                }

                if (rule.name == 'categories' && typeof rule.value !== 'undefined') {
                    this.removeFilterFromGroupByValue(rule.name, rule.value[0])
                } else if (rule.name != 'keywords') {
                    this.clearFilter(rule.name)
                }
            },

            deleteAllRules() {
                this.query.rules = []
                this.clearAllFilters()
            },

            getLastId() {
                let lastId = 0;
                for (var i = 0; i < this.query.rules.length; i++) {
                    if (typeof this.query.rules[i].condition != 'undefined') {
                        for (var j = 0; j < this.query.rules[i].rules.length; j++) {
                            if (this.query.rules[i].rules[j].id > lastId) {
                                lastId = this.query.rules[i].rules[j].id;
                            }
                        }
                    } else {
                        if (this.query.rules[i].id > lastId) {
                            lastId = this.query.rules[i].id;
                        }
                    }
                }
                return lastId;
            },

            currentRuleLevelByField(rule, inGroup) {
                var index = {};
                this.query.rules.forEach(function(item, i) {
                    if (inGroup) {
                        if (item === rule) {
                            index = {'level_1' : i, count:-1};
                            return;
                        }
                    } else {
                        if (typeof item.id !== 'undefined') {
                            if (item.name == rule.name) {
                                index = {'level_1': i, 'count': 1};
                                return;
                            }
                        } else {
                            if (typeof item.condition !== 'undefined') {
                                item.rules.forEach(function(elem, j) {
                                    if (typeof elem.id !== 'undefined') {
                                        if (elem.name == rule.name) {
                                            index = {'level_1': i, 'level_2': j, 'count': 2};
                                            return;
                                        }
                                    }
                                });
                            }
                        }
                    }
                });
                return index;
            },

            syncRulesWithFilters(filterName) {
                let filters = this.filters[filterName]

                return this.query.rules.some(function (rule, i, object) {
                    if (typeof rule.id !== 'undefined' && rule.name === filterName) {
                        let exists = filters.some(function (filter) {
                            return filter.value === rule.value[0]
                        });

                        if (!exists) {
                            object.splice(i, 1)
                            return true
                        }
                    } else {
                        if (typeof rule.condition !== 'undefined') {
                            return rule.rules.some(function (elem, j, innerObject) {
                                if (typeof elem.id !== 'undefined' && elem.name === filterName) {
                                    let exists = filters.some(function (filter) {
                                        return filter.value === elem.value[0]
                                    });

                                    if (!exists) {
                                        innerObject.splice(j, 1)
                                        return true
                                    }
                                }
                            })
                        }
                    }
                });
            },

            addFilterRule(ruleToAdd) {
                let result = this.query.rules.some(function(rule, i, object) {
                    if (typeof rule.id !== 'undefined' && rule.name === ruleToAdd.name) {
                        let updatedRule = {
                            'condition': 'OR',
                            'rules': [rule, ruleToAdd],
                        }

                        object.splice(i, 1, updatedRule)

                        return true
                    } else {
                        if (typeof rule.condition !== 'undefined') {
                            return rule.rules.some(function (elem, j, innerObject) {
                                if (typeof elem.id !== 'undefined' && elem.name === ruleToAdd.name) {
                                    innerObject.push(ruleToAdd)
                                    return true
                                }
                            })
                        }
                    }
                });

                if (!result) {
                    this.query.rules.push(ruleToAdd)
                }
            },

            updateFilterRule(name) {
                let filterValue = this.filters[name]

                let nextId = this.getLastId() + 1;

                let rule = {
                    "id": nextId,
                    "name": name,
                    "label": name,
                    "negative": false,
                }

                let needDelete = false

                if (['lastFundingDate','foundedYear','lastInteraction'].includes(name)) {

                    if (filterValue[0] === null || filterValue[1] === null) {
                        needDelete = true
                        this.showCalendar[name] = false
                    } else {
                        rule.value = this.dateHelper.decodeInterval(filterValue)

                        if (name === 'lastInteraction') {
                            rule.valueAlias = this.dateHelper.decodeDate(filterValue[0]) + '-' + this.dateHelper.decodeDate(filterValue[1])
                        } else {
                            rule.valueAlias = this.dateHelper.decodeYear(filterValue[0]) + '-' + this.dateHelper.decodeYear(filterValue[1])
                        }
                    }
                } else if (name === 'similarTracker') {
                    if (!filterValue) {
                        needDelete = true
                    }
                    else {
                        rule.label = "Similar to tracker"
                        rule.value = filterValue.id
                        rule.valueAlias = filterValue.name
                    }
                } else if (name === 'categories') {
                    if (!filterValue.length) {
                        needDelete = true
                    } else {
                        rule.label = "Category"
                        rule.value = [filterValue.slice(-1)[0].value]
                        rule.valueAlias = filterValue.slice(-1)[0].name
                    }
                } else if (name === 'tags') {
                    if (!filterValue.length) {
                        needDelete = true
                    } else {
                        rule.label = "Tag"
                        rule.value = [filterValue.slice(-1)[0].value]
                        rule.valueAlias = filterValue.slice(-1)[0].name
                    }
                } else if (name === 'trackers') {
                    if (!filterValue.length) {
                        needDelete = true
                    } else {
                        let values = this.arrayHelper.arrayColumn(filterValue, 'id')
                        let labels = this.arrayHelper.arrayColumn(filterValue, 'name')
                        rule.value = values
                        rule.valueAlias = labels.join(', ')
                    }
                } else if (name === 'irlScore') {
                    rule.label = "IRL Score"
                    rule.value = filterValue
                    rule.valueAlias = filterValue[0].toString() + '-' + filterValue[1].toString()
                } else {
                    if (name === 'clientsCategories') {
                        rule.label = "Client's Industry"
                    }

                    if (!filterValue.length) {
                        needDelete = true
                    } else {
                        let values = this.arrayHelper.arrayColumn(filterValue, 'value')
                        let labels = this.arrayHelper.arrayColumn(filterValue, 'name')
                        rule.value = values
                        rule.valueAlias = labels.join(', ')
                    }
                }

                let level = this.currentRuleLevelByField(rule)

                if (!needDelete) {
                    if (['categories','tags'].includes(name)) {
                        let isDeleting = this.syncRulesWithFilters(name);

                        if (!isDeleting) {
                            this.addFilterRule(rule)
                        }
                    } else {
                        if (typeof level.level_1 === "undefined") {
                            this.query.rules.push(rule)
                        } else {
                            this.updateRule(rule, level)
                        }
                    }
                }
                else {
                    this.deleteRule(rule, level)
                }

            },

            updateCustomDataFilter(filters) {
                let filtersNames = []

                for (let i in filters) {
                    let filter = filters[i]
                    let nextId = this.getLastId() + 1;

                    let rule = {
                        "id": nextId,
                        "name": filter.name,
                        "label": filter.label,
                        "negative": false,
                        "value": filter.value,
                        "valueAlias": filter.valueAlias,
                    }

                    let level = this.currentRuleLevelByField(rule)
                    if (typeof level.level_1 == "undefined") {
                        this.query.rules.push(rule)
                    } else {
                        this.updateRule(rule, level)
                    }

                    filtersNames.push(filter.name)
                    this.customFieldsRules.push(filter.name)
                }

                for (let i in this.customFieldsRules) {
                    let name = this.customFieldsRules[i]
                    let index = filtersNames.indexOf(name)

                    if (index < 0) {
                        let rule = {
                            "name": name
                        }

                        let level = this.currentRuleLevelByField(rule)

                        this.deleteRule(rule, level)
                    }
                }

                this.customFieldsRules = filtersNames
            },

            updateRule(rule, level) {

                if (level['count'] === 1) {
                    this.query.rules[level['level_1']].value = rule.value;
                    this.query.rules[level['level_1']].valueAlias = rule.valueAlias;

                    // exceptional case for that condition
                    if (rule.name === 'trackers' && rule.value[0] === 'all') {
                        this.query.rules[level['level_1']].negative = false;
                    }
                }

                if (level['count'] === 2) {
                    this.query.rules[level['level_1']].rules[level['level_2']].value = rule.value;
                    this.query.rules[level['level_1']].rules[level['level_2']].valueAlias = rule.valueAlias;

                    // exceptional case for that condition
                    if (rule.name === 'trackers' && rule.value[0] === 'all') {
                        this.query.rules[level['level_1']].rules[level['level_2']].negative = false;
                    }
                }
            },

            toggleCondition(rule, inGroup, oldCond) {

                inGroup = inGroup || false;
                var currentLevel = this.currentRuleLevel(rule, inGroup);
                var nextLevel  = this.nextRuleLevel(rule, currentLevel);
                var usecase = this.determineCase(currentLevel, nextLevel);

                if (usecase == '1') {
                    if (oldCond == 'AND') {
                        let spliced = this.query.rules.splice(currentLevel['level_1'], 2);
                        let newRule = {
                            'condition' : 'OR',
                            'rules'     : spliced,
                        }
                        this.query.rules.splice(currentLevel['level_1'], 0, newRule);
                    }
                }
                if (usecase == '2') {
                    if (oldCond == 'AND') {
                        let spliced = this.query.rules.splice(currentLevel['level_1'], 1);
                        let newIndex = nextLevel['level_1'] - 1;
                        this.query.rules[newIndex].rules.unshift(spliced[0]);
                    }
                }
                if (usecase == '3') {
                    if (oldCond == 'AND') {
                        if (currentLevel.count === -1) {
                            let extractedRules = this.query.rules[nextLevel['level_1']].rules;
                            this.query.rules.splice(nextLevel['level_1'], 1);
                            for (let i = 0; i < extractedRules.length; i++) {
                                this.query.rules[currentLevel['level_1']].rules.push(extractedRules[i]);
                            }
                        }
                    } else {
                        var groupLength = this.query.rules[nextLevel['level_1']].rules.length;

                        // Just two items in the group
                        if (currentLevel['level_2'] === 0 && nextLevel['level_2'] === (groupLength-1)) {
                            let extractedRues = this.query.rules[currentLevel['level_1']].rules;
                            this.query.rules.splice(currentLevel['level_1'], 1);

                            for (let i = 0; i < extractedRues.length; i++) {
                                var position = currentLevel['level_1'] + i;
                                this.query.rules.splice(position, 0, extractedRues[i]);
                            }
                        }

                        // First item of the group
                        else if (currentLevel['level_2'] === 0) {
                            let extracted = this.query.rules[currentLevel['level_1']].rules.splice(currentLevel['level_2'], 1);
                            this.query.rules.splice(currentLevel['level_1'], 0, extracted[0]);
                        }

                        // Next is last of group
                        else if (nextLevel['level_2'] === (groupLength-1)) {
                            let extracted = this.query.rules[nextLevel['level_1']].rules.splice(nextLevel['level_2'], 1);
                            this.query.rules.splice(currentLevel['level_1']+1, 0, extracted[0]);
                        }

                        else {

                            // Next and current in the middle
                            var itemsToExtract = groupLength - nextLevel['level_2'];
                            var extracted = this.query.rules[nextLevel['level_1']].rules.splice(nextLevel['level_2'], itemsToExtract);
                            let newRule = {
                                'condition': 'OR',
                                'rules': extracted,
                            }
                            this.query.rules.splice(currentLevel['level_1'] + 1, 0, newRule);
                        }
                    }
                }
                if (usecase == '4') {
                    if (oldCond == 'AND') {
                        // When current is group
                        if (currentLevel.count === -1) {
                            // Add next rule to current group
                            var spliced = this.query.rules.splice(nextLevel['level_1'], 1);
                            this.query.rules[currentLevel['level_1']].rules.push(spliced[0]);
                        }
                    }
                }
            },

            nextRuleLevel(rule, level) {
                var rules = this.query.rules;
                var currentIndex = level['level_1'];
                var nextIndex = currentIndex + 1;
                var nextLevel = {'level_1': nextIndex, 'count': 1};

                // Check if current is inside group
                if (level['count'] === 2) {
                    var secondeLevelNextIndex = level['level_2'] + 1;
                    // First search inside current group
                    if (typeof rules[currentIndex].rules[secondeLevelNextIndex] != 'undefined') {
                        Object.assign(nextLevel, {
                            'level_1' : currentIndex,
                            'level_2' : secondeLevelNextIndex,
                            'count': 2
                        });
                        return nextLevel;
                    }
                }

                if (typeof rules[nextIndex] != 'undefined') {
                    if (typeof rules[nextIndex].condition != 'undefined') {
                        Object.assign(nextLevel, {'level_2' : 0, 'count': 2});
                    }
                } else {
                    if (typeof rules[currentIndex].condition != 'undefined') {
                        var nextSecondLevel = level['level_2'] + 1;
                        Object.assign(nextLevel, {
                            'level_1' : currentIndex,
                            'level_2' : nextSecondLevel,
                            'count'   : 2
                        });
                    }
                }

                return nextLevel;
            },

            determineCase(currentLevel, nextLevel) {
                // Case 1: current and next in level 1
                if (currentLevel.count === 1 && nextLevel.count === 1) {
                    return '1';
                }

                // Case 2: current in level 1 and next in group
                if (currentLevel.count === 1 && nextLevel.count === 2) {
                    return '2';
                }

                // Case 3: current and next in group
                if (currentLevel.count === 2 && nextLevel.count === 2) {
                    return '3';
                }
                // Case 3: current and next in group
                if (currentLevel.count === -1 && nextLevel.count === 2) {
                    return '3';
                }

                // Case 4: current in group and next in level 1
                if (currentLevel.count === 2 && nextLevel.count === 1) {
                    return '4';
                }
                // Case 4: current in group and next in level 1
                if (currentLevel.count === -1 && nextLevel.count === 1) {
                    return '4';
                }

                return '0';
            },

            prependRule: function(rule) {
                var rules = [];
                var id = 1;
                rule.id = id;
                rules.push(rule);

                var groups = [];
                var oldRules = JSON.parse ( JSON.stringify ( this.query.rules) );

                for (var i in oldRules) {
                    var newRule = oldRules[i];
                    if (typeof newRule.rules !== 'undefined') {
                        groups.push({
                            id: (id+1),
                            count: newRule.rules.length
                        });
                        for (var j in newRule.rules) {
                            ++id;
                            var subRule =  newRule.rules[j];
                            subRule.id = id;
                            rules.push(subRule);
                        }
                    }
                    else {
                        ++id;
                        newRule.id = id;
                        rules.push(newRule);
                    }
                }

                this.query.rules = rules

                for (let gr in groups) {
                    let groupId = groups[gr].id;
                    let count = groups[gr].count;

                    for (let i in this.query.rules) {
                        if (parseInt(this.query.rules[i].id) == groupId) {
                            var ruleIndex = i;
                            rule = this.query.rules[i];
                            this.toggleCondition(rule, false, 'AND')
                        }
                    }

                    for (j = 1; j < count - 1; j++) {
                        rule = this.query.rules[ruleIndex];

                        this.toggleCondition(rule, true, 'AND')
                    }
                }

            },

            invertRule(rule) {
                if (rule.name === "ecosystems" && this.$store.getters.isFreeEnterprise) {
                    this.showUpgradeModal()
                    return;
                }

                var level = this.currentRuleLevel(rule);
                // Level 1
                if (level['count'] === 1) {
                    this.query.rules[level['level_1']].negative = !this.query.rules[level['level_1']].negative;
                }

                // Level 2
                if (level['count'] === 2) {
                    this.query.rules[level['level_1']].rules[level['level_2']].negative = !this.query.rules[level['level_1']].rules[level['level_2']].negative;
                }
            },

            updateRuleProps(rule, props) {
                var level = this.currentRuleLevel(rule);
                // Level 1
                if (level['count'] === 1) {
                    for (let i in props) {
                        this.query.rules[level['level_1']][i] = props[i];
                    }
                }

                // Level 2
                if (level['count'] === 2) {
                    for (let i in props) {
                        this.query.rules[level['level_1']].rules[level['level_2']][i] = props[i];
                    }
                }
            },

            normalizeQuery() {
                let rules = []

                this.query.rules.forEach(rule => {
                    if (rule.condition !== undefined) {
                        if (rule.rules.length > 1) {
                            rules.push(rule)
                        }
                        else if (rule.rules.length === 1) {
                            rules.push(rule.rules[0])
                        }
                    }
                    else {
                        rules.push(rule)
                    }
                })

                this.query.rules = rules
            },
        }
    }

export default equation
