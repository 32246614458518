<template>
    <main class="enter-page" v-if="showLoginForm">
        <header class="header">
            <span class="logo"></span>
        </header>

        <header class="form--enter__header">
            <h1 class="heading1">Log In</h1>
            <p>Need an account? <router-link to="/signup">Sign Up</router-link></p>
        </header>

        <Login
            v-on:login="login">
        </Login>

        <footer class="enter-page__footer">
            <h5 class="heading5">Featured in:</h5>
            
            <ul class="list-logos">
                <li class="list-logos__bloomberg"></li>
                <li class="list-logos__inc"></li>
                <li class="list-logos__usnews"></li>
                <li class="list-logos__entrepreneur"></li>
                <li class="list-logos__upstart"></li>
            </ul>
        </footer>
    </main>
</template>

<script>

import auth from '../../services/auth.service';
import Login from "@/components/common/Login"

export default {
    components: {
        Login
    },

    data() {
        return {
            showLoginForm: false
        }
    },

    mounted() {

        if (this.$route.query.email && this.$route.query.sso_token) {

            auth.login({ username: this.$route.query.email, sso_token: this.$route.query.sso_token})
                .then(response => {
                    this.errors = response.data.errors

                    if (this.errors.length) {
                        this.$store.commit('authError')
                    }
                    else if (response.data.user.token) {
                        this.$store.commit('authSuccess', response.data.user)

                        if (this.$store.state.user.need_accept_terms) {
                            this.$store.commit('setNeedAcceptTerms', 1)

                            this.$nextTick(() => {
                                this.$store.commit('setNeedAcceptTerms', 0)
                            })
                        }

                        if (this.$route.query.redirectTo) {
                            this.$router.push(this.$route.query.redirectTo)
                        } else {
                            this.$router.push('/')
                        }

                    }
                })
                .catch(error => {
                    this.$store.commit('authError')
                    console.log(error)
                })
        }
        else {
            this.showLoginForm = true
        }
    },

    methods: {
        login(params) {
            if (this.$store.state.returnUrl.length) {
                this.$router.push(this.$store.state.returnUrl)
                this.$store.commit('setReturnUrl', '')
            } else if (this.$store.getters.isStartup) {
                if (this.$store.state.user.hasAccessToEnterprises) {
                    this.$router.push('/enterprises');
                } else {
                    this.$router.push('/')
                }
            } else if (this.$route.query.returnTo) {
                this.$router.push(this.$route.query.returnTo)
            } else {
                this.$router.push('/')
            }

            if (params.showProfile) {
                this.$emit('showProfile', {'showCompanyProfile': this.$store.state.user.company_type === 'ecosystem'})
            } else if (params.trialExpired) {
                this.$emit('showTrialExpired', {'plan': params.trialExpired})
            } else {
                this.$emit('login')
            }

            if (params.showVideo) {
                this.$emit('show-video', params.showVideo)
            }
        }
    }
}
</script>

<style scoped>

</style>
