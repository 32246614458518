<template>
    <div class="modal-edit-tracker modal-edit-tracker--sub modal-edit-creation-tracker" v-show="showModal">
        <header class="modal-header">
            <h5 class="modal-title">
                
            </h5>
            <button type="button" aria-label="Close" class="close" @click="close"></button>
            <b-button v-if="showPrevStepButton" variant="primary" size="lg" @click="prevStep()" class="back-link"></b-button>
        </header>
        
        <!--<a class="link" @click="showModal = false">Close</a>-->

        <template v-if="step === 1">
            <div class="modal-sub modal-sub--dark">
                <h1 class="modal__title modal__title--primary pt-sm-5 mt-0">Create New Tracker</h1>
                <ul class="create-tracker-list">
                    <li class="create-tracker-list__blank">
                        <a @click="setTrackerType(trackerTypes.PUBLIC)">
                            <h4 class="heading4">Public Challenge</h4>
                            <p>Startups can find your project and apply</p>
                        </a>
                    </li>
                    <li class="create-tracker-list__template">
                        <a @click="setTrackerType(trackerTypes.PRIVATE)">
                            <h4 class="heading4">Private Tracker</h4>
                            <p>Users within your company add and evaluate startups</p>
                        </a>
                    </li>
                </ul>
                
                <div v-if="$store.getters.isEnterprise" class="create-tracker-alternative">
                    <div class="create-tracker-alternative__or">- Or -</div>
                    
                    <!--<a @click="setTrackerType(trackerTypes.ECOSYSTEMS)" class="create-tracker-alternative__inner">
                        <h4 class="heading4">Ecosystem Tracker</h4>

                        <p>Follow VCs, universities, etc, & their startups</p>
                    </a>-->

                    <b-dropdown text="More Tracker Types" class="dropdown-tracker">
                        <b-dropdown-item @click="setTrackerType(trackerTypes.ECOSYSTEMS)">
                            <h4 class="heading4">Ecosystem Tracker</h4>

                            <p>Follow VCs, universities, etc, & their startups</p>
                        </b-dropdown-item>
                        <b-dropdown-item class="dropdown-tracker__poc" @click="setTrackerType(trackerTypes.POC)">
                            <h4 class="heading4">POC Tracker</h4>

                            <p>View POCs in a time-based layout</p>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
        </template>
        
        <div class="modal-sub" v-if="step > 1">
            <div class="modal-sub__inner">

                <template v-if="selectTemplateStep">
            
                    <h1 class="modal__title">Choose a Template</h1>

                    <template v-if="templatesList.default.length">
                        <h4 class="heading4">SwitchPitch Templates</h4>

                        <ul class="list-template">
                            <li v-for="template in templatesList.default" :key="template.id">
                                <a @click="selectedTemplate = template; selectTemplateStep = false">
                                    <div>
                                        <h5 class="heading5 heading5--secondary">{{template.name}}</h5>
                                        <p>{{template.description}}</p>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </template>

                    <template v-if="templatesList.custom.length">
                        <h4 class="heading4">{{ $store.state.user.company_name }} Templates</h4>

                        <ul class="list-template">
                            <li class="list-template__list" v-for="template in templatesList.custom" :key="template.id">
                                <a @click="selectedTemplate = template; selectTemplateStep = false">
                                    <div>
                                        <h5 class="heading5 heading5--secondary">{{template.name}}</h5>
                                        <p>{{template.description}}</p>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </template>
                </template>

                <template v-else>
            <!--        First tracker page-->
                    <template v-if="step === 2">
                        <h1 class="modal__title mb-1">Add Tracker Details</h1>
                        <template v-if="selectedTemplate">
                            <ul class="list-template list-template--w-change">
                                <li class="list-template__list">
                                    <a @click="selectTemplateStep = true">
                                        <div>
                                            <h5 class="heading5 heading5--secondary">{{selectedTemplate.name}}</h5>
                                        </div>
                                        <span class="link link-normal">Change</span>
                                    </a>
                                </li>
                            </ul>
                        </template>
                        <template v-else>
                            <ul class="list-template-new">
                                <li class="list-template__list">
                                    <a @click="selectTemplateStep = true" v-if="tracker.type === trackerTypes.PRIVATE && canEditInfo && (templatesList.default.length || templatesList.custom.length)" class="link link-normal">Change tracker template</a>
                                </li>
                            </ul>
                        </template>

                        <b-row class="mb-4 mt-4">
                            <b-col cols="12" sm="7" class="mb-4 mb-sm-0">
                                <div class="form form-modal">
                                    <h5 class="heading5 heading5--secondary">Tracker name</h5>
                                    <b-form-input class="form-control--mid" v-model="tracker.name" placeholder="" :disabled="!canEditInfo"></b-form-input>
                                </div>
                            </b-col>
                            <b-col cols="12" sm="5">
                                <template v-if="foldersList.length">
                                    <h5 class="heading5 heading5--secondary">Folder <span class="heading5__light">(Optional)</span></h5>

                                    <multiselect class="multiselect-mid"
                                                 v-model="tracker.folder"
                                                 :options="foldersList"
                                                 select-label=""
                                                 deselect-label=""
                                                 label="name"
                                                 track-by="name"
                                                 :disabled="!canEditInfo"
                                    >
                                    </multiselect>
                                </template>
                            </b-col>
                        </b-row>

                        <h5 class="heading5 heading5--secondary">Description</h5>

                        <div class="mb-3">
                            <editor
                                v-model="tracker.description"
                                :disabled="!canEditInfo"
                                :apiKey="apiKeys.tinyMCE"
                                :init="defaults.editorOptions"
                            >
                            </editor>
                        </div>


                        <!--                        Submitter fields-->
                        <div class="d-flex align-items-baseline justify-content-end mb-4" v-if="canManageSubmitterFields || tracker.submitterFields.length">
                            <a v-if="canManageSubmitterFields" @click="$bvModal.show('addSubmitterFieldModal' + prefix)" class="link">+Add More Details Fields</a>
                        </div>
                        
                        <div class="modal-edit-creation-tracker__wrapper">
                        <div v-for="(field, index) in tracker.submitterFields" :key="field.id" class="mb-4">
                            <h5 class="heading5 heading5--secondary">{{field.field.name}}</h5>

                            <div v-if="typeof detailFieldsErrors[index] !== 'undefined'">
                                <span class="form__error error">{{detailFieldsErrors[index]}}</span>
                            </div>
                            <div class="form-control-wrapper">

                                <custom-field
                                    :field="tracker.submitterFields[index].field"
                                    :value="tracker.submitterFields[index].value"
                                    :inline="false"
                                    :instantUpdate="false"
                                    :index="index"
                                    :disabled="!canFillSubmitterFields"
                                    v-on:customValueChanged="submitterValueChanged"
                                >
                                </custom-field>

                                <a v-if="canManageSubmitterFields" @click="tracker.submitterFields.splice(index, 1)" class="link-remove link-remove--blue link ml-2"></a>
                            </div>
                        </div>
                        </div>

                        <b-modal :id="'addSubmitterFieldModal' + prefix" title="Add Field">
                            <select v-model="newSubmitField" class="selectbox selectbox-mid">
                                <option :value="null">
                                    Select field
                                </option>

                                <option v-for="(field, id) in actualSubmitterFields" :key="id" :value="field">
                                    {{field.name}}
                                </option>
                            </select>

                            <template #modal-footer>
                                <b-button variant="primary" @click="addSubmitterField" :disabled="!newSubmitField">Add</b-button>
                            </template>
                        </b-modal>

<!--                        Pipeline-->
                        <template v-if="![trackerTypes.ECOSYSTEMS, trackerTypes.POC].includes(tracker.type)">
                            <div class="pipeline-cover">
                                <template v-if="tracker.enablePipeline">
    
                                    <multiselect class="multiselect-mid"
                                                 v-model="tracker.pipeline"
                                                 :options="pipelinesList"
                                                 select-label=""
                                                 deselect-label=""
                                                 label="name"
                                                 track-by="name"
                                                 :disabled="!canEditInfo"
                                    >
                                    </multiselect>
                                </template>
                            
                                <div class="content-switcher content-switcher--secondary">
                                    <h5 v-if="!tracker.enablePipeline" class="heading5 heading5--secondary mt-1 mr-4">Pipeline Disabled</h5>
                                    <div class="content-switcher__slider" :class="{'enabled': tracker.enablePipeline}">
                                        <input type="checkbox" id="content-switcher__slider" :disabled="!canEditInfo" v-model="tracker.enablePipeline">
                                        <label for="content-switcher__slider"></label>
                                    </div>
                                </div>
                            
                                <template v-if="tracker.enablePipeline">
                                    <div class="link-wrapper m-0 p-0">
                                        <a class="link-wrapper__edit text-nowrap" v-if="tracker.pipeline && canEditInfo && $store.state.user.permissions.indexOf('tracker-template-manage') >= 0" @click="$refs.EditPipeline.open(tracker.pipeline.id)">Edit Stages</a>
                                    </div>
                                </template>
                            </div>

                            <template v-if="tracker.enablePipeline">
                                <ul v-if="tracker.pipeline" class="rows-list rows-list--pipeline">
                                    <li v-for="(stage, index) in tracker.pipeline.steps" :key="index">
                                        <div v-if="enableStagesEmail" class="d-flex justify-content-between w-100">
                                            <div class="input-checkbox">
                                                <input :id="'enable-email-'+index" type="checkbox" v-model="emailStages[index]">
                                                <label :for="'enable-email-'+index">{{stage.name}}</label>
                                            </div>

                                            <a v-if="emailStages[index]" @click="openEmailTemplate(stage)" class="link link--email">Edit email</a>
                                        </div>

                                        <template v-else>
                                            {{stage.name}}
                                        </template>
                                    </li>
                                </ul>

                                <div v-if="tracker.type === trackerTypes.PUBLIC && tracker.pipeline" class="pipeline-stages-header">
                                    <div class="input-checkbox input-checkbox--secondary">
                                        <input id="enable-stages-email" type="checkbox" v-model="enableStagesEmail">
                                        <label for="enable-stages-email">Notify startups of stage changes</label>
                                    </div>
                                </div>
                            </template>
                            <!--temporary disable
                            <template v-if="tracker.enablePipeline">
                                <h5 class="heading5 heading5--secondary">Default view</h5>

                                <div class="radio-block mb-4">
                                    <div class="radio-block__item radio-block__item--list">
                                        <input type="radio" value="list" :disabled="!canEditInfo" v-model="tracker.defaultView" id="dw1">
                                        <label for="dw1">List</label>
                                    </div>
                                    <div class="radio-block__item radio-block__item--pipeline">
                                        <input type="radio" value="pipeline" :disabled="!canEditInfo" v-model="tracker.defaultView" id="dw2">
                                        <label for="dw2">Funnel</label>
                                    </div>
                                </div>
                            </template>
                            -->
                        </template>

                        <template v-if="tracker.type === trackerTypes.POC">

                            <div class="pipeline-cover">
                                <span class="pipeline-cover__status">Tracker statuses:</span>
                                <multiselect class="multiselect-mid"
                                             v-model="tracker.timelinePreset"
                                             :options="timelinePresetsList"
                                             select-label=""
                                             deselect-label=""
                                             label="name"
                                             track-by="name"
                                             :disabled="!canEditInfo"
                                >
                                </multiselect>

                                <div class="link-wrapper m-0 p-0">
                                    <a class="link-wrapper__edit text-nowrap" v-if="tracker.timelinePreset && canEditInfo" @click="$refs.EditTimelinePreset.open(tracker.timelinePreset.id)">Edit Template</a>
                                </div>
                            </div>

                            <ul v-if="tracker.timelinePreset" class="rows-list rows-list--pipeline list-colors">
                                <li v-for="(status, index) in tracker.timelinePreset.statuses" :key="index">
                                    {{status.name}}
                                    
                                    <div class="list-colors__input">
                                        <span class="list-colors__input__text">Color:</span>
                                        
                                        <div class="list-colors__input__wrapper">
                                            <input type="color" disabled :value="status.color">
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </template>

                        <b-row>
                            <b-col>
                                <h5 class="heading5 heading5--secondary">Permissions</h5>
                            </b-col>
                            <b-col class="text-right">
                                <template v-if="canManageRoles">

                                    <a @click="openManagePermissionsModal" class="link text-nowrap">Manage Permissions</a>

                                    <b-modal :id="'managePermissions' + prefix" modal-class="managePermissions modal-w-md" title="Manage Permissions">
                                        <ul class="rows-list mb-3">
                                            <li class="rows-list__form rows-list__form--transparent flex-sm-nowrap flex-wrap">

                                                <multiselect class="multiselect-mid mb-sm-0 mb-4"
                                                             v-model="newPermission.holder"
                                                             :options="actualPermissionHolders"
                                                             placeholder="Select User"
                                                             select-label=""
                                                             deselect-label=""
                                                             label="name"
                                                             track-by="name"
                                                >
                                                    <template slot="option" slot-scope="props">
                                                        <template v-if="props.option.type === 'group'">
                                                            <div class="d-flex align-items-center">
                                                                <div class="mr-2">
                                                                    <Avatar v-if="props.option.avatar"
                                                                            :username="props.option.name"
                                                                            :src="props.option.avatar"
                                                                            :size="30">
                                                                    </Avatar>
                                                                    <font-awesome-icon v-else :icon="['fa', 'users']" size="lg"  />
                                                                </div>
                                                                {{ props.option.name }}
                                                            </div>

                                                        </template>
                                                        <template v-else>
                                                            <div class="d-flex align-items-center">
                                                                <div class="mr-2">
                                                                    <Avatar
                                                                        :username="props.option.name"
                                                                        :src="props.option.avatar"
                                                                        :size="30">
                                                                    </Avatar>
                                                                </div>
                                                                {{ props.option.name }}
                                                            </div>
                                                        </template>
                                                    </template>
                                                </multiselect>

                                                <template v-if="roles.length">
                                                    <span class="text-nowrap ml-sm-4">Add as</span>

                                                    <b-dropdown class="dropdown--tertiary" variant="link" ref="roleDropdown" toggle-class="text-decoration-none" no-caret>

                                                        <template #button-content>
                                                            {{ newPermission.role.name }}
                                                        </template>

                                                        <b-dropdown-header>
                                                            <ul class="modal-list">
                                                                <li v-for="role in roles" :key="role.id" @click="newPermission.role = role; $refs.roleDropdown.hide()">
                                                                    <h5 class="heading5 heading5--secondary">{{ role.name }}</h5>
                                                                    <p>{{ role.description }}</p>
                                                                </li>
                                                            </ul>
                                                        </b-dropdown-header>

                                                    </b-dropdown>
                                                </template>

                                                <b-button @click="addPermission" variant="primary" size="md" :disabled="!newPermission.holder">Add</b-button>
                                            </li>

                                            <li v-for="(permission, index) in tracker.permissions" :key="index">

                                                <div class="image-block image-block--rounded">
                                                    <Avatar
                                                        :username="permission.name"
                                                        :src="permission.avatar"
                                                        :size="30">
                                                    </Avatar>
                                                </div>

                                                <h5 class="heading5 heading5--secondary">{{ permission.name }}</h5>

                                                <b-dropdown v-if="roles.length" class="dropdown--tertiary modal-list" variant="link" toggle-class="text-decoration-none" no-caret>
                                                    <template #button-content>
                                                        <h5 class="heading5 heading5--secondary text-capitalize">{{permission.role.name}}</h5>
                                                    </template>

                                                    <b-dropdown-item v-for="(role, index) in roles" :key="index" @click="permission.role = role">
                                                        <h5 class="heading5 heading5--secondary">{{role.name}}</h5>
                                                        <p>{{role.description}}</p>
                                                    </b-dropdown-item>
                                                </b-dropdown>

                                                <a @click="tracker.permissions.splice(index, 1)" class="remove-action" href="#">Remove</a>
                                            </li>
                                        </ul>

                                        <template #modal-footer>
                                            <b-button size="lg" variant="primary" class="ml-3" @click="closeManagePermissionsModal">
                                                Save
                                            </b-button>
                                        </template>
                                    </b-modal>
                                </template>
                            </b-col>
                        </b-row>

                        <ul class="rows-list rows-list--summary">
                            <li v-for="(permission, index) in tracker.permissions" :key="index">

                                <div class="image-block image-block--rounded">
                                    <Avatar
                                        :username="permission.name"
                                        :src="permission.avatar"
                                        :size="30">
                                    </Avatar>
                                </div>

                                <h5 class="heading5 heading5--secondary">{{ permission.name }}</h5>
                                <h5 class="heading5 heading5--secondary text-capitalize" :id="'permission'+permission.id">
                                    {{permission.role.name}}
                                    <b-tooltip :target="'permission'+permission.id" placement="right" variant="light" :title="permission.role.description"></b-tooltip>
                                </h5>


                                <a v-if="canManageRoles" @click="tracker.permissions.splice(index, 1)" class="link-remove link" href="#"></a>
                            </li>
                        </ul>

<!--                        Custom Fields-->
                        <div v-if="tracker.type !== trackerTypes.ECOSYSTEMS && (customFieldsCount || canEditCustomFields)" class="d-flex align-items-center mb-4">

                            <h5 class="heading5 heading5--secondary mr-4 mb-0">Custom Fields</h5>
                            <template v-if="customFieldsCount"><span class="fw-bold mr-2">{{customFieldsCount}} Added</span></template>
                            
                            <div class="link-wrapper m-0 p-0">
                                <a @click="$bvModal.show('editCustomFieldsModal' + prefix)" class="link m-0" :class="{'link-edit link-wrapper__edit': customFieldsCount && canEditCustomFields}">{{editCustomFieldsLabel}}</a>
                            </div>
                            
                            <b-modal :id="'editCustomFieldsModal' + prefix" title="Customizations">
<!--                                <h1 class="modal__title">Customizations</h1>-->

                                <template v-if="canEditQuestions">

                                    <p v-if="tracker.type === trackerTypes.PUBLIC" class="modal-text">
                                        Add fields startups and reviewers fill out in order to apply tracker / evaluate startups.
                                    </p>

                                    <p v-else class="modal-text">
                                        Add fields reviewers fill out in order to better evaluate startups.
                                    </p>
                                </template>

                                <template v-if="tracker.type === trackerTypes.PUBLIC && (canEditQuestions || tracker.questions.length)">
                                    <h5 class="heading5 heading5--secondary">Questions</h5>
                                    <p class="modal-text tracker-hint">Fields startups will need to fill out when applying to this tracker</p>

                                    <draggable v-model="tracker.questions" class="list-drag-n-drop--full list-drag-n-drop--complex" :class="{'list-drag-n-drop': canEditQuestions}" handle=".draggable">
                                        <div v-for="(question, index) in tracker.questions" :key="'q'+index" class="w-100" :class="{draggable: canEditQuestions}">
                                            <div class="mb-2 pr-0" :class="{'list-drag-n-drop__item': canEditQuestions}">
                                                <span></span>
                                                <multiselect class="multiselect-mid select-questions"
                                                             v-model="tracker.questions[index].field"
                                                             :options="actualQuestionsList"
                                                             :disabled="!canEditQuestions"
                                                             select-label=""
                                                             deselect-label=""
                                                             label="name"
                                                             track-by="name"
                                                >
                                                    <!-- Customize selected option to add title. Show full question name on mouse over (when question is long) -->
                                                    <template slot="singleLabel" slot-scope="props"><span class="p-0" :title="props.option.name">{{ props.option.name }}</span></template>
                                                </multiselect>
                                            </div>


                                            <div v-if="canEditQuestions" class="d-flex align-items-center justify-content-between mb-4">
                                                <div class="input-checkbox" v-if="tracker.questions[index].field && ['static_text','section_text'].indexOf(tracker.questions[index].field.field_type) < 0">
                                                    <input :id="'question-mandatory-'+index" type="checkbox" v-model="tracker.questions[index].required">
                                                    <label :for="'question-mandatory-'+index">Make mandatory</label>
                                                </div>

                                                <div class="text-right ml-auto">
                                                    <a @click="tracker.questions.splice(index, 1)" class="link-remove"></a>
                                                </div>
                                            </div>
                                        </div>
                                    </draggable>

                                    <div class="link-wrapper" v-if="canEditQuestions">
                                        <a @click="tracker.questions.push({field: null, required: 0})" class="link">+ Add Question</a>
                                    </div>
                                </template>

                                <template v-if="tracker.reviewsEnabled === 1 && (canEditReviewFields || tracker.reviewerFields.length)">
                                    <h5 class="heading5 heading5--secondary">Reviewer Fields</h5>
                                    <p class="modal-text tracker-hint">Fields your team members will fill out in order to review startups</p>

                                    <draggable v-model="tracker.reviewerFields" class="list-drag-n-drop--full list-drag-n-drop--complex" :class="{'list-drag-n-drop': canEditReviewFields}" handle=".draggable">
                                        <div v-for="(field, index) in tracker.reviewerFields" :key="'r'+index" class="w-100" :class="{draggable: canEditQuestions}">

                                            <div class="mb-2 pr-0" :class="{'list-drag-n-drop__item': canEditQuestions}">
                                                <span></span>
                                                <multiselect class="multiselect-mid mb-2"
                                                             v-model="tracker.reviewerFields[index].field"
                                                             :options="actualReviewerFieldsList"
                                                             :disabled="!canEditReviewFields"
                                                             select-label=""
                                                             deselect-label=""
                                                             label="name"
                                                             track-by="name"
                                                >
                                                </multiselect>
                                            </div>

                                            <div v-if="canEditReviewFields" class="d-flex align-items-center justify-content-between mb-4">
                                                <div class="input-checkbox">
                                                    <input :id="'mandatory-'+index" type="checkbox" v-model="tracker.reviewerFields[index].required">
                                                    <label :for="'mandatory-'+index">Make mandatory</label>
                                                </div>

                                                <div v-if="canEditReviewFields" class="text-right">
                                                    <a @click="tracker.reviewerFields.splice(index, 1)" class="link-remove"></a>
                                                </div>
                                            </div>
                                        </div>
                                    </draggable>

                                    <div class="link-wrapper" v-if="canEditReviewFields">
                                        <a @click="tracker.reviewerFields.push({field: null, required: 0})" class="link">+ Add Field</a>
                                    </div>
                                </template>

                                <h5 class="heading5 heading5--secondary">Startup Diligence Fields</h5>
                                <p class="modal-text tracker-hint">Fields that can be used as columns in your tracker that can be filled in by users</p>

                                <div v-for="(field, index) in tracker.diligenceFields" :key="'d'+index">
                                    <multiselect class="multiselect-mid mb-2"
                                                 v-model="tracker.diligenceFields[index]"
                                                 :options="actualDiligenceFields"
                                                 :disabled="!canEditInfo"
                                                 select-label=""
                                                 deselect-label=""
                                                 label="name"
                                                 track-by="name"
                                    >
                                    </multiselect>
                                    <div class="text-right mb-2" v-if="canEditInfo">
                                        <a @click="tracker.diligenceFields.splice(index, 1)" class="link link-normal">Remove</a>
                                    </div>
                                </div>

                                <div class="link-wrapper" v-if="canEditInfo">
                                    <a @click="tracker.diligenceFields.push(null)" class="link">+ Add Field</a>
                                </div>

                                <template v-if="tracker.type === trackerTypes.PRIVATE && $store.getters.isPremiumEnterprise && (canEditQuestions || tracker.questions.length)">
                                    <h5 class="heading5 heading5--secondary">Startup Questions</h5>
                                    <p class="modal-text tracker-hint">Fields startups will be prompted to fill out in order to be evaluated.<br>
                                        When startup questions are present, adding a startup to your tracker will send them a form with your fields to fill out.</p>

                                    <draggable v-model="tracker.questions" class="list-drag-n-drop--full list-drag-n-drop--complex" :class="{'list-drag-n-drop': canEditQuestions}" handle=".draggable">
                                        <div v-for="(question, index) in tracker.questions" :key="'q'+index" class="w-100" :class="{draggable: canEditQuestions}">
                                            <div class="mb-2 pr-0" :class="{'list-drag-n-drop__item': canEditQuestions}">
                                                <span></span>
                                                <multiselect class="multiselect-mid"
                                                             v-model="tracker.questions[index].field"
                                                             :options="actualQuestionsList"
                                                             :disabled="!canEditQuestions"
                                                             select-label=""
                                                             deselect-label=""
                                                             label="name"
                                                             track-by="name"
                                                >
                                                </multiselect>
                                            </div>

                                            <div v-if="canEditQuestions" class="d-flex align-items-center justify-content-between mb-4">
                                                <div class="text-right ml-auto">
                                                    <a @click="tracker.questions.splice(index, 1)" class="link">Remove</a>
                                                </div>
                                            </div>
                                        </div>
                                    </draggable>

                                    <div class="link-wrapper" v-if="canEditQuestions">
                                        <a @click="addQuestion" class="link">+ Add Field</a>
                                    </div>
                                </template>

                                <template #modal-footer>
                                    <b-button size="lg" variant="primary" @click="$bvModal.hide('editCustomFieldsModal' + prefix)">
                                        Close
                                    </b-button>
                                </template>
                            </b-modal>
                        </div>
                        
                       <h5 class="heading5 heading5--secondary mb-4">Timeline <a v-if="!showTimeline" @click="showTimeline = true" class="link ml-4">+ Add</a></h5>

                        <div v-if="showTimeline" class="mb-4">
                            <date-picker v-model="timeline"
                                         format = "MM/DD/YYYY"
                                         :disabled="!canEditInfo"
                                         placeholder="Select Start / End Date"
                                         range>
                            </date-picker>
                        </div>

                        <template v-if="tracker.type === trackerTypes.PUBLIC">
                            <h5 class="heading5 heading5--secondary">Posted Date</h5>

                            <div class="mb-4">
                                <date-picker v-model="postDate"
                                             format = "MM/DD/YYYY"
                                             :disabled="!canEditInfo"
                                             placeholder="Select Posted Date"
                                             >
                                </date-picker>
                            </div>
                        </template>

                        <h5 class="heading5 heading5--secondary">Categories</h5>
                        <div class="mb-4">
                            <multiselect class="multiselect-mid multiselect-modern"
                                v-model="tracker.categories"
                                :options="$store.state.categories"
                                :multiple="true"
                                select-label=""
                                deselect-label=""
                                :disabled="!canEditInfo"
                                label="name"
                                track-by="name"
                            >
                            </multiselect>
                        </div>

                        <template v-if="$store.getters.isEnterprise && !$store.getters.isEventLimited && tracker.type === trackerTypes.PRIVATE && (enableEcosystemSharing || canChangeVisibility)">
                            <h5 class="heading5 heading5--secondary mb-2">Ecosystem Sharing</h5>
                            <div class="form__item">

                                <div class="input-checkbox mb-4">
                                    <input id="enable-ecosystem-sharing" type="checkbox" :disabled="!canChangeVisibility" v-model="enableEcosystemSharing" @change="setEcosystemSharing">
                                    <label for="enable-ecosystem-sharing">Enable ecosystem sharing</label>
                                </div>

                                <div class="shared-ecosystem-label" v-if="tracker.access_type !== trackerTypes.PRIVATE && canChangeVisibility">
                                    <p style="line-height: normal" class="modal-text"> {{stringHelper.trackerAccess(this.tracker)}} <a class="heading-edit" @click="setVisibility"></a></p>
                                </div>
                            </div>

                            <AccessSettings
                                v-if="canChangeVisibility"
                                ref="accessSettings"
                                :prefix="prefix"
                                :init-access-type="tracker.access_type"
                                :init-ecosystems="tracker.ecosystems"
                                v-on:saved="accessSettingsChanged"
                                v-on:cancelled="accessSettingsCancelled"
                            >
                            </AccessSettings>

                        </template>

                        <h5 class="heading5 heading5--secondary mb-2">Reviews</h5>

                        <div class="d-flex mb-4">
                            <div class="input-radio mr-3">
                                <input type="radio" value="1" :disabled="!canEditInfo" v-model="tracker.reviewsEnabled" id="tr1" name="tr">
                                <label for="tr1">Enabled (recommended)</label>
                            </div>
                            <div class="input-radio">
                                <input type="radio" value="0" @click="disableReviews" :disabled="!canEditInfo" v-model="tracker.reviewsEnabled" id="tr2" name="tr">
                                <label for="tr2">Disabled</label>
                            </div>
                        </div>

                        <template v-if="$store.getters.isEnterprise">
                            <h5 class="heading5 heading5--secondary mb-2">Notifications</h5>

                            <div class="input-checkbox">
                                <input id="enable-notifications" type="checkbox" v-model="tracker.enableNotifications">
                                <label for="enable-notifications">Enable Notifications</label>
                            </div>
                        </template>

                        <template v-if="tracker.type === trackerTypes.PUBLIC">
                            <h5 class="heading5 heading5--secondary mb-3">Settings</h5>
                            <div class="input-checkbox">
                                <input id="only-referral" type="checkbox" :disabled="!canEditInfo" v-model="tracker.onlyReferral">
                                <label for="only-referral">Only startups which have referral URL can apply</label>
                            </div>

                            <div class="input-checkbox mt-3">
                                <input id="need-complete-profile" type="checkbox" :disabled="!canEditInfo" v-model="tracker.needCompleteProfile">
                                <label for="need-complete-profile">Require startups to complete profiles to apply</label>
                            </div>

                            <div v-if="tracker.type === trackerTypes.PUBLIC" class="input-checkbox mt-3">
                                <input id="notify-startups" type="checkbox" :disabled="!canEditInfo" v-model="tracker.notifyStartups">
                                <label for="notify-startups">Notify not applied startups the day before end date</label>
                            </div>
                        </template>

                        <div class="input-checkbox mt-2 mb-2" v-if="$store.state.user.company_id === constants.companyPfizer && tracker.type === trackerTypes.PRIVATE">
                            <h5 class="heading5 heading5--secondary">Settings</h5>
                            <input id="referral-link-access" type="checkbox" :disabled="!canEditInfo" v-model="tracker.InternalAccessById">
                            <label for="referral-link-access">Any user who has URL can see tracker</label>
                        </div>


                    </template>

                </template>

                <footer class="modal__bottom" v-if="!selectTemplateStep">
                    <div v-if="tracker.type === trackerTypes.PUBLIC">
                        <a @click="showTermsModal" class="link">+ Add Terms and Conditions</a>
                    </div>
                    
                    <div class="modal__bottom__inner">
                        <div class="buttons-wrapper justify-content-end">
                            <b-button class="m-0" :disabled="!tracker.name.length || saving" variant="primary" size="lg" @click="save">
                                <template v-if="id > 0">Update</template><template v-else>Create</template> Tracker
                                <b-spinner class="spinner--button ml-1 mb-1" small label="Saving..." v-if="saving"></b-spinner>
                            </b-button>
                        </div>
                    </div>
                </footer>
            </div>

        </div>

        <EditField
            ref="EditPipeline"
            type="tracker_diligence"
            fieldType="pipeline"
            :sectionId="0"
            :index="'tracker-pipeline-'+prefix"
            v-on:fieldSaved="updatePipeline"
            v-on:modalClosedWithoutChanges="resetPipeline"
        >
        </EditField>

        <EditField
            ref="EditTimelinePreset"
            type="tracker_diligence"
            fieldType="POC_preset"
            :sectionId="0"
            :index="'tracker-timeline-preset-'+prefix"
            v-on:fieldSaved="updateTimelinePreset"
            v-on:modalClosedWithoutChanges="resetTimelinePreset"
        >
        </EditField>

        <EditField
            ref="EditQuestion"
            type="tracker_question"
            :sectionId="0"
            :index="'tracker-question-'+prefix"
            v-on:fieldSaved="updateQuestion"
        >
        </EditField>

        <EditField
            ref="EditReview"
            type="tracker_review"
            :sectionId="0"
            :index="'tracker-review-'+prefix"
            v-on:fieldSaved="updateReview"
        >
        </EditField>

        <b-modal :id="'modal-edit-tracker-terms' + prefix" title="Add Terms and Conditions" no-enforce-focus>

            <editor
                v-model="tracker.termsAndConditions"
                :disabled="!canEditInfo"
                :apiKey="apiKeys.tinyMCE"
                :init="defaults.editorOptions"
            >
            </editor>

            <template #modal-footer>
                <b-button variant="primary" size="lg" @click="$bvModal.hide('modal-edit-tracker-terms' + prefix)">Save</b-button>
            </template>
        </b-modal>

        <b-modal :id="'modal-edit-email-template-tracker' + prefix" title="Edit email template" modal-class="modal-w-md">
            <p>This email will be sent automatically when a startup is added to the tracker stage {{currentStage.name}}.<br>
                <template v-pre>
                    Enter "{{Stage name}}" or "{{Project name}}" to automatically add the relevant titles, even if you change them later.
                </template>
            </p>

            <div class="form form-modal mb-4">
                Subject
                <b-form-input class="form-control--mid" v-model="emailTemplate.subject"></b-form-input>
            </div>

            <div class="form form-modal mb-4">
                Message
                <textarea v-model="emailTemplate.body">

                </textarea>
            </div>

            <template #modal-footer>
                <b-button variant="primary" size="lg" @click="saveEmailTemplate">Save</b-button>
            </template>
        </b-modal>

        <b-modal :id="'modal-edit-tracker-scout' + prefix" title="Before You Enable Startup Questions...">
            <p>Startup questions and auto-scout can't be turned on at the same time. Disable auto-scout for this tracker in the
            startups tab by selecting Saved Searches > Manage Saved Searches</p>

            <template #modal-footer>
                <b-button variant="primary" size="lg" @click="$bvModal.hide('modal-edit-tracker-scout' + prefix)">Okay</b-button>
            </template>
        </b-modal>

    </div>
</template>

<script>
import trackers from "@/services/trackers.service"
import trackersFolders from "@/services/trackers-folders.service"
import trackersTemplates from "@/services/trackers-templates.service"
import customFields from "@/services/custom-fields.service"
import companyService from "@/services/company.service";
import team from "@/services/team.service"
import multiselect from "vue-multiselect"
import DatePicker from "vue2-datepicker"
import EditField from "@/components/customFields/EditField"
import CustomField from "@/components/customFields/CustomField"
import AccessSettings from "@/components/trackers/AccessSettings"
import Avatar from 'vue-avatar'
import editor from "@/components/Editor"
import Vue from "vue";
import draggable from "vuedraggable";
import {validateNumericValues} from "@/helpers/functions";
import {mapActions, mapGetters} from "vuex";
import {trackerTypes} from "@/constants/trackers";

export default {
    props: {
        folderId: Number,
        folderType: {
            type: String,
            default: "",
        },
        prefix: {
            type: String,
            default: "",
        }
    },

    components: {
        multiselect,
        DatePicker,
        EditField,
        CustomField,
        Avatar,
        editor,
        draggable,
        AccessSettings,
    },

    data() {
        return {
            id: 0,
            step: 1,
            disabledTypeSelection: false,

            selectedTemplate: null,

            selectTemplateStep: false,

            templatesList: {
                default: [],
                custom: [],
            },

            foldersList: [],
            pipelinesList: [],
            timelinePresetsList: [],
            timeline: [null, null],
            postDate: null,
            questionsList: [],
            reviewerFieldsList: [],
            submitterFieldsList: [],
            diligenceFieldsList: [],
            currentQuestionIndex: -1,
            currentReviewIndex: -1,
            newSubmitField: null,
            enableStagesEmail: false,
            enableEcosystemSharing: false,
            hasScout: false,
            showTimeline: false,

            permissionHolders: [],
            newPermission: {
                holder: null,
                role: 'manager',
            },

            tracker: {
                name: '',
                folder: null,
                categories: [],
                description: '',
                pipeline: null,
                timelinePreset: null,
                startDate: null,
                endDate: null,
                postDate: null,
                type: null,
                enablePipeline: true,
                defaultView: 'pipeline',
                reviewsEnabled: 1,
                needCompleteProfile: 0,
                notifyStartups: 1,
                onlyReferral: 0,
                InternalAccessById: 0,
                questions: [],
                reviewerFields: [],
                submitterFields: [],
                diligenceFields: [],
                permissions: [],
                userPermissions: [],
                termsAndConditions: "",
                access_type: "",
                ecosystems: [],
                emailStages: [],
                enableNotifications: 1,
            },

            initReferral: 0,

            emailStages: [],
            emailTemplates: {},

            initDiligenceFields: [],
            initReviewerFields: [],
            fieldFilesNumber : 0,
            watchChanges: false,
            forceClose: false,
            showModal: false,
            currentPipeline: null,
            currentTimelinePreset: null,
            detailFieldsErrors: {},

            currentStage: {
                id: 0,
                name: "",
            },

            emailTemplate: {
                subject: "",
                body: "",
            },

            saving: false
        }
    },

    watch: {
        timeline() {
            if (this.timeline[0]) {
                let interval = this.dateHelper.encodeIntervalForServer(this.timeline)
                this.tracker.startDate = interval[0]
                this.tracker.endDate = interval[1]
            }
            else {
                this.tracker.startDate = null
                this.tracker.endDate = null
            }
        },

        postDate() {
            if (this.postDate) {
                this.tracker.postDate = this.dateHelper.encodeDateForServer(this.postDate);
            } else {
                this.tracker.postDate = null;
            }
        },

        'tracker.type'() {
            this.getFoldersList();

            if (this.tracker.type === trackerTypes.POC) {
                trackers.getTimelinePresets().then(response => {
                    this.timelinePresetsList = response.data

                    if (!this.id && this.timelinePresetsList.length) {
                        this.tracker.timelinePreset = this.timelinePresetsList[0]
                    }

                    this.timelinePresetsList.push({
                        id: -1,
                        name: "Create New Preset",
                        statuses: [],
                    })
                })
            }
        },

        'tracker.folder'() {
            if (this.tracker.folder && this.tracker.folder.id && !this.id) {
                this.tracker.permissions = this.tracker.folder.permissions
            }
        },

        'tracker.access_type'() {
            if (this.tracker.type !== trackerTypes.PUBLIC) {
                this.enableStagesEmail = false
            }
        },

        'tracker.enablePipeline'() {
            if (!this.tracker.enablePipeline) {
                this.tracker.defaultView = 'list'
            }
        },

        'tracker.pipeline'() {
            if (this.tracker.pipeline) {
                if (this.tracker.pipeline.id === -1) {
                    this.$refs.EditPipeline.open(0)
                    this.tracker.pipeline = null
                }
                else {
                    this.currentPipeline = this.tracker.pipeline
                    let emailsArray = this.arrayHelper.parseNumber(this.tracker.emailStages)
                    this.emailStages = []

                    this.tracker.pipeline.steps.forEach((item) => {
                        this.emailStages.push(emailsArray.indexOf(item.id) >= 0)
                    })
                }
            }
        },

        'tracker.timelinePreset'() {
            if (this.tracker.timelinePreset) {
                if (this.tracker.timelinePreset.id === -1) {
                    this.$refs.EditTimelinePreset.open(0)
                    this.tracker.timelinePreset = null
                }
                else {
                    this.currentTimelinePreset = this.tracker.timelinePreset
                }
            }
        },

        'tracker.questions': {
            deep: true,
            handler() {
                this.tracker.questions.forEach((item, index) => {
                    if (item.field && item.field.id === -1) {
                        this.currentQuestionIndex = index
                        this.tracker.questions[index].field = null
                        this.$refs.EditQuestion.open(0)
                    }
                })
            }
        },

        'tracker.reviewerFields': {
            deep: true,
            handler() {
                this.tracker.reviewerFields.forEach((item, index) => {

                    if (item.field && item.field.id === -1) {
                        this.currentReviewIndex = index
                        this.tracker.reviewerFields[index].field = null
                        this.$refs.EditReview.open(0)
                    }
                })
            }
        },

        'tracker.onlyReferral'() {
            if (this.initReferral && !this.tracker.onlyReferral && !confirm("Disabling this option will remove the referral url.\n" +
                 "Do you want to proceed?")) {
                this.$nextTick(() => {
                    this.tracker.onlyReferral = 1;
                })
            }
        },

        selectedTemplate() {
            if (this.selectedTemplate) {
                this.tracker.name = this.selectedTemplate.tracker_name
                this.tracker.description = this.selectedTemplate.description
                this.tracker.defaultView = this.selectedTemplate.default_view
                this.tracker.reviewsEnabled = this.selectedTemplate.reviews_enabled
                this.tracker.pipeline = this.selectedTemplate.pipeline

                if (this.selectedTemplate.start_date || this.selectedTemplate.end_date) {
                    this.timeline = this.dateHelper.decodeIntervalFromServer([this.selectedTemplate.start_date, this.selectedTemplate.end_date])
                }

                this.tracker.categories = this.selectedTemplate.categories

                this.tracker.reviewerFields = []
                this.selectedTemplate.reviewer_fields.forEach(item => {
                    this.tracker.reviewerFields.push({
                        field: item,
                        required: 0
                    })
                })

                this.tracker.diligenceFields = this.selectedTemplate.diligence_fields

                this.tracker.submitterFields = []
                this.selectedTemplate.submitter_fields.forEach(item => {
                    let field = this.submitterFieldsList.find(arrayItem => arrayItem.id === item.id)

                    this.tracker.submitterFields.push({
                        field: field,
                        value: {
                            value: "",
                            options: [],
                            files: [],
                            links: []
                        },
                    })
                })
            }
        },

        enableStagesEmail() {
            if (!this.enableStagesEmail) {
                for (let i in this.emailStages) {
                    this.emailStages[i] = false
                }
            }
        },
    },

    computed: {
        ...mapGetters(['isEcosystem','roles']),

        trackerTypes() {
            return trackerTypes;
        },

        actualPermissionHolders() {
            let array = []
            this.permissionHolders.forEach(item => {
                if (!this.arrayHelper.exists(this.tracker.permissions, ['type', 'id'], [item.type, item.id])) {
                    array.push(item)
                }
            })

            return array
        },

        canEditInfo() {
            if (this.id) {
                return this.tracker.userPermissions.indexOf('tracker-edit') >= 0
            }
            else {
                return true
            }
        },

        canChangeVisibility() {
            return this.tracker.userPermissions.indexOf('tracker-add-ecosystem') >= 0
        },

        canManageRoles() {
            return this.tracker.userPermissions.indexOf('tracker-permissions-edit') >= 0
        },

        canManageSubmitterFields() {
            return this.tracker.userPermissions.indexOf('tracker-submitterFields-edit') >= 0
        },

        canFillSubmitterFields() {
            if (this.id) {
                return this.tracker.userPermissions.indexOf('tracker-submitterFields-value-edit') >= 0
            }
            else {
                return true
            }
        },

        canEditQuestions() {
            if (this.tracker.type === trackerTypes.PRIVATE) {
                return this.tracker.userPermissions.indexOf('tracker-questions-edit-private') >= 0
            }
            else {
                return this.tracker.userPermissions.indexOf('tracker-questions-edit-public') >= 0
            }
        },

        canEditReviewFields() {
            return this.tracker.userPermissions.indexOf('tracker-reviewerFields-edit') >= 0
        },

        canEditCustomFields() {
            return this.canEditQuestions || this.canEditReviewFields || this.canEditInfo
        },

        actualSubmitterFields() {
            let newArray = []

            this.submitterFieldsList.forEach(field => {
                if (!this.tracker.submitterFields.some(item => item.field.id === field.id)) {
                    newArray.push(field)
                }
            })

            return newArray
        },

        actualDiligenceFields() {
            return this.arrayHelper.arrayDiff(this.diligenceFieldsList, this.tracker.diligenceFields, 'id')
        },

        actualReviewerFieldsList() {
            return this.arrayHelper.arrayDiff(this.reviewerFieldsList, this.tracker.reviewerFields, 'id', 'field')
        },

        actualQuestionsList() {
            return this.arrayHelper.arrayDiff(this.questionsList, this.tracker.questions, 'id', 'field')
        },

        showPrevStepButton() {
            return this.step === 2 && !this.id && this.$store.state.accountType !== 'free' && !this.disabledTypeSelection
        },

        customFieldsCount() {
            return this.tracker.questions.length + this.tracker.reviewerFields.length + this.tracker.diligenceFields.length
        },

        editCustomFieldsLabel() {
            if (this.customFieldsCount) {
                if (this.canEditQuestions || this.canEditReviewFields || this.canEditInfo) {
                    return "Edit"
                }
                else {
                    return "View"
                }
            }
            else {
                return "+ Add"
            }
        },
    },

    mounted() {
        if (this.$store.getters.isEnterprise) {
            this.fetchRoles();
        }
    },

    methods: {
        ...mapActions(['fetchRoles']),

        setTrackerType(type) {

            if (type === trackerTypes.ECOSYSTEMS) {
                this.tracker.enablePipeline = false
                this.tracker.reviewerFields = []
            }

            if (type === trackerTypes.POC) {
                this.tracker.enablePipeline = false
            }

            if (type === trackerTypes.PUBLIC && !this.id && !this.tracker.questions.length) {
                this.addDefaultQuestions()
            }

            this.tracker.type = type
            this.step = 2

            this.$nextTick(() => {
                this.watchChanges = true
            })
        },

        prevStep() {
            this.init()
        },

        getFieldsList() {
            trackers.getPipelinesList().then(response => {
                this.pipelinesList = response.data

                if (!this.id && this.pipelinesList.length) {
                    this.tracker.pipeline = this.pipelinesList[0]
                }

                if (this.$store.state.user.permissions.includes('funnel-create')) {
                    this.pipelinesList.push({
                        id: -1,
                        name: "Create New Funnel",
                        steps: [],
                    })
                }
            })

            customFields.getFieldsArray('tracker_question').then(response => {
                this.questionsList = response.data

                this.questionsList.push({
                    id: -1,
                    name: "Create New Question",
                })

                if (this.tracker.type === trackerTypes.PUBLIC && !this.id && !this.tracker.questions.length) {
                    this.addDefaultQuestions();
                }
            })

            customFields.getFieldsArray('tracker_review').then(response => {
                this.reviewerFieldsList = response.data

                this.reviewerFieldsList.push({
                    id: -1,
                    name: "Create New Field",
                })
            })

            customFields.getFieldsArray('tracker_submit').then(response => {
                this.submitterFieldsList = response.data
            })

            customFields.getFieldsArray('tracker_diligence').then(response => {
                this.diligenceFieldsList = response.data
            })

            trackersTemplates.getTemplates().then(response => {
                this.templatesList = response.data
            })
        },

        getFoldersList(folderId = 0) {
            let folderType = this.tracker.type === 'ecosystems' ? 'private' : this.tracker.type;

            trackersFolders.getList(folderId, folderType).then(response => {
                this.foldersList = response.data

                if (this.foldersList.length) {
                    this.foldersList.unshift({id: 0, name: 'None'})

                    if (this.folderId) {
                        this.tracker.folder = this.foldersList.find(item => item.id === this.folderId);
                    }
                }
            })
        },

        getPermissionHolders() {
            team.getPermissionHolders().then(response => {
                this.permissionHolders = response.data
                this.addDefaultRole();
            })
        },

        init() {
            this.getFieldsList()
            this.getPermissionHolders()
            this.resetTracker()

            if (this.id) {
                this.step = 2

                trackers.getTrackerEdit(this.id).then(response => {
                    this.tracker.name = response.data.name
                    this.tracker.type = response.data.type
                    this.tracker.description = response.data.description
                    this.tracker.enablePipeline = response.data.enable_pipeline
                    this.tracker.defaultView = response.data.default_view
                    this.tracker.reviewsEnabled = response.data.reviews_enabled
                    this.tracker.needCompleteProfile = response.data.need_complete_profile
                    this.tracker.onlyReferral = response.data.only_referral
                    this.tracker.InternalAccessById = response.data.internal_access_by_id
                    this.tracker.folder = response.data.folder
                    this.tracker.startDate = response.data.start_date
                    this.tracker.endDate = response.data.end_date
                    this.tracker.questions = response.data.questions
                    this.tracker.reviewerFields = response.data.reviewer_fields
                    this.tracker.submitterFields = response.data.submitter_fields
                    this.tracker.diligenceFields = response.data.diligence_fields
                    this.tracker.permissions = response.data.permissions
                    this.tracker.userPermissions = response.data.user_permissions
                    this.tracker.termsAndConditions = response.data.terms_and_conditions
                    this.tracker.access_type = response.data.access_type
                    this.tracker.ecosystems = response.data.ecosystems
                    this.tracker.emailStages = response.data.stages_with_email
                    this.tracker.notifyStartups = response.data.notify_startups
                    this.tracker.enableNotifications = response.data.enable_notifications
                    this.initReferral = this.tracker.onlyReferral;

                    this.enableStagesEmail = !!this.tracker.emailStages.length
                    this.enableEcosystemSharing = this.tracker.access_type !== 'private'
                    this.hasScout = response.data.has_scout

                    this.initDiligenceFields = this.arrayHelper.cloneArray(this.tracker.diligenceFields)
                    this.initReviewerFields = this.arrayHelper.cloneArray(this.tracker.reviewerFields)

                    if (response.data.start_date !== null) {
                        this.timeline = this.dateHelper.decodeIntervalFromServer([response.data.start_date, response.data.end_date])
                        this.showTimeline = true
                    }

                    this.postDate = this.dateHelper.decodeDateFromServer(response.data.post_date);

                    this.tracker.pipeline = response.data.pipeline
                    this.tracker.timelinePreset = response.data.timeline_preset
                    this.tracker.categories = response.data.categories

                    if (this.tracker.folder) {
                        this.getFoldersList(this.tracker.folder.id);
                    } else {
                        this.getFoldersList();
                    }

                    this.$nextTick(() => {
                        this.watchChanges = true
                    })
                })
            } else {
                this.getFoldersList(this.folderId);

                if (['free','event_limited'].includes(this.$store.state.accountType)) {
                    this.step = 2;
                } else if (this.isEcosystem) {
                    this.tracker.type = trackerTypes.PRIVATE;
                    this.step = 2;
                    this.disabledTypeSelection = true;
                } else if (this.folderType === trackerTypes.PUBLIC) {
                    this.tracker.type = trackerTypes.PUBLIC;
                    this.step = 2;
                    this.disabledTypeSelection = true;
                } else {
                    this.step = 1;
                }

                if (this.folderId) {
                    trackersFolders.getInfo(this.folderId).then(response => {
                        this.tracker.permissions = response.data.permissions
                    })
                }
                else {
                    this.tracker.folder = null

                    trackers.getNewTrackerInfo().then(response => {
                        this.tracker.userPermissions = response.data.user_permissions
                    })
                }

                if (this.pipelinesList.length) {
                    this.tracker.pipeline = this.pipelinesList[0]
                }

                if (this.timelinePresetsList.length) {
                    this.tracker.timelinePreset = this.timelinePresetsList[0]
                }

                if (this.roles.length) {
                    this.tracker.permissions.push(
                        {
                            type: 'user',
                            id: this.$store.state.user.id,
                            name: this.$store.state.user.name,
                            role: this.roles[0],
                            avatar: this.$store.state.user.avatar,
                        }
                    )

                    this.addDefaultRole();
                }
            }
        },

        resetTracker() {
            this.tracker.name = ""
            this.tracker.type = trackerTypes.PRIVATE
            this.tracker.description = ""
            this.tracker.enablePipeline = true
            this.tracker.defaultView = "pipeline"
            this.tracker.reviewsEnabled = 1
            this.tracker.needCompleteProfile = 1
            this.tracker.onlyReferral = 0
            this.tracker.pipeline = null
            this.tracker.timelinePreset = null
            this.tracker.categories = []
            this.tracker.startDate = null
            this.tracker.endDate = null
            this.tracker.questions = []
            this.tracker.submitterFields = []
            this.tracker.diligenceFields = []
            this.tracker.reviewerFields = []
            this.tracker.permissions = []
            this.tracker.userPermissions = []
            this.tracker.ecosystems = []
            this.tracker.termsAndConditions = ""
            this.tracker.access_type = "private"
            this.tracker.enableNotifications = 1
            this.timeline = [null, null]
            this.selectTemplateStep = false
            this.selectedTemplate = null
            this.enableStagesEmail = false
            this.hasScout = false
            this.showTimeline = false
            this.initReferral = 0

            this.forceClose = false
            this.watchChanges = false
            this.emailTemplates = {}
        },

        open(id, disabledTypeSelection = false) {

            this.id = id

            if (this.$store.state.accountType !== 'premium' && !id) {
                trackers.getCount().then(response => {
                    if (response.data >= this.constants.enterpriseLimits.trackers[this.$store.state.accountType]) {
                        this.showNeedUpgrade('trackers')
                    } else {
                        this.init()
                        this.showModal = true
                        document.body.classList.add('modal-open')
                    }
                })
            } else {
                this.init()
                this.showModal = true
                document.body.classList.add('modal-open')
            }

            if (disabledTypeSelection) {
                this.disabledTypeSelection = disabledTypeSelection
                this.setTrackerType(trackerTypes.PRIVATE)
            }
        },

        close() {
            this.showModal = false
            document.body.classList.remove('modal-open')
        },

        save() {
            let [errors, hasErrors] = validateNumericValues(this.tracker.submitterFields)

            this.detailFieldsErrors = errors

            if (hasErrors) {
                return
            }

            this.saving = true;

            let removed = false
            this.initDiligenceFields.forEach(item => {
                if (!this.arrayHelper.exists(this.tracker.diligenceFields, 'id', item.id)) {
                    removed = true
                    return
                }
            })

            if (!removed || confirm("Fields you removed from tracker will cause their values removing for existing startups. Do you want to proceed?")) {
                this.tracker.updatedEmailStages = this.emailStages
                this.tracker.updatedEmailTemplates = this.emailTemplates

                trackers.save(this.id, this.tracker).then((response) => {

                    this.fieldFilesNumber = this.fileHelper.fieldsWithNewFilesNumber(this.tracker.submitterFields)
                    let trackerId = response.data

                    if (this.fieldFilesNumber) {
                        this.tracker.submitterFields.forEach(field => {

                            let formData = new FormData();
                            let hasFiles = false

                            field.value.files.forEach(file => {
                                if (file.id === 0) {
                                    formData.append('file[]', file)
                                    hasFiles = true
                                }
                            })

                            if (hasFiles) {
                                trackers.saveSubmitterFiles(trackerId, field.field.id, formData).then(() => {
                                    this.fieldFilesSaved()
                                    this.saving = false;
                                }).catch(() => {
                                    this.saving = false;
                                });
                            } else {
                                this.saving = false;
                            }
                        })
                    }
                    else {
                        this.$emit('trackerSaved', trackerId)
                        this.forceClose = true
                        this.close()
                        this.saving = false;

                        Vue.notify({
                            group: 'app',
                            duration: 5000,
                            type: 'success',
                            text: this.id ? "Tracker updated" : "Tracker created" + '<a class="btn btn-outline-success btn-notify btn-md" href="/trackers/' + trackerId + '" target="_blank">Open Tracker</a>'
                        })
                    }
                }).catch(() => {
                    this.saving = false;
                });
            } else {
                this.saving = false;
            }
        },

        fieldFilesSaved() {
            --this.fieldFilesNumber

            if (!this.fieldFilesNumber) {
                this.$emit('trackerSaved')
                this.forceClose = true
                this.close()

                Vue.notify({
                    group: 'app',
                    type: 'success',
                    text: this.id ? "Tracker updated" : "Tracker created"
                })
            }
        },

        addSubmitterField() {
            this.tracker.submitterFields.push({
                field: this.newSubmitField,
                value: {
                    value: "",
                    options: [],
                    files: [],
                    links: []
                },
            })

            this.newSubmitField = null
            this.$bvModal.hide('addSubmitterFieldModal' + this.prefix)
        },

        updatePipeline(id) {
            trackers.getPipelinesList().then(response => {
                this.pipelinesList = response.data
                this.pipelinesList.push({
                    id: -1,
                    name: "Create New Funnel",
                    steps: [],
                })

                this.tracker.pipeline = this.pipelinesList.find(item => item.id === id)
            })
        },

        updateTimelinePreset(id) {
            trackers.getTimelinePresets().then(response => {
                this.timelinePresetsList = response.data
                this.timelinePresetsList.push({
                    id: -1,
                    name: "Create New Preset",
                    statuses: [],
                })

                this.tracker.timelinePreset = this.timelinePresetsList.find(item => item.id === id)
            })
        },

        updateQuestion(id) {
            customFields.getFieldsArray('tracker_question').then(response => {
                this.questionsList = response.data
                this.questionsList.push({
                    id: -1,
                    name: "Create New Question",
                })

                let index = this.arrayHelper.findIndexByColumnValue(this.questionsList, 'id', id)
                this.tracker.questions[this.currentQuestionIndex].field = this.questionsList[index]
            })
        },

        updateReview(id) {
            customFields.getFieldsArray('tracker_review').then(response => {
                this.reviewerFieldsList = response.data
                this.reviewerFieldsList.push({
                    id: -1,
                    name: "Create New Field",
                })

                let index = this.arrayHelper.findIndexByColumnValue(this.reviewerFieldsList, 'id', id)
                this.tracker.reviewerFields[this.currentReviewIndex].field = this.reviewerFieldsList[index]
            })
        },

        submitterValueChanged({index, value}) {
            this.tracker.submitterFields[index].value = value

            if (this.tracker.submitterFields[index].field.type === 'date') {
                this.tracker.submitterFields[index].value.value = this.dateHelper.decodeDate(this.tracker.submitterFields[index].value.value)
            }
        },

        addPermission() {
            this.tracker.permissions.push(
                {
                    type: this.newPermission.holder.type,
                    id: this.newPermission.holder.id,
                    name: this.newPermission.holder.name,
                    role: this.newPermission.role,
                    avatar: this.newPermission.holder.avatar,
                }
            )

            this.newPermission.holder = null
        },

        showTermsModal() {
            this.$bvModal.show('modal-edit-tracker-terms' + this.prefix)
            this.$nextTick(() => {
            })
        },

        checkShares(e) {
            if (this.tracker.ecosystems.length) {
                if (!confirm("This tracker shared to " + this.tracker.ecosystems.length + " " + this.stringHelper.getNoun('ecosystem', this.tracker.ecosystems.length) + " which will lose access to tracker. Are you sure?")) {
                    e.preventDefault()
                }
            }
        },

        accessSettingsChanged(params) {
            this.tracker.access_type = params.accessType
            this.tracker.ecosystems = params.ecosystems
        },

        accessSettingsCancelled() {
            if (this.tracker.access_type === 'private') {
                this.enableEcosystemSharing = false
            }
        },

        setVisibility() {
            this.$refs.accessSettings.open()
        },

        openEmailTemplate(step) {
            this.currentStage = step

            if (typeof this.emailTemplates[this.currentStage.id] !== "undefined") {
                this.emailTemplate = this.emailTemplates[this.currentStage.id]
                this.$bvModal.show('modal-edit-email-template-tracker' + this.prefix)
            }
            else {
                companyService.getStageEmailTemplateEdit(this.id, this.currentStage.id).then(response => {
                    this.emailTemplate = response.data
                    this.$bvModal.show('modal-edit-email-template-tracker' + this.prefix)
                })
            }
        },

        saveEmailTemplate() {
            this.emailTemplates[this.currentStage.id] = this.emailTemplate
            this.$bvModal.hide('modal-edit-email-template-tracker' + this.prefix)
        },

        setEcosystemSharing() {
            if (this.enableEcosystemSharing) {
                this.setVisibility()
            }
            else {
                this.tracker.access_type = 'private'
                this.tracker.ecosystems = []
            }
        },

        addQuestion() {
            if (this.id) {
                trackers.hasScout(this.id).then(response => {
                    if (response.data) {
                        this.$bvModal.show('modal-edit-tracker-scout' + this.prefix)
                    }
                    else {
                        this.tracker.questions.push({field: null, required: 0})
                    }
                })
            }
            else {
                this.tracker.questions.push({field: null, required: 0})
            }
        },

        openManagePermissionsModal() {
            this.$bvModal.show('managePermissions' + this.prefix)
        },

        closeManagePermissionsModal() {
            this.$bvModal.hide('managePermissions' + this.prefix)
        },

        resetPipeline() {
            this.tracker.pipeline = this.currentPipeline
        },

        resetTimelinePreset() {
            this.tracker.timelinePreset = this.currentTimelinePreset
        },

        disableReviews(e) {
            if (this.tracker.reviewsEnabled && this.tracker.reviewerFields.length) {
                if (!confirm("Disable Reviews?\n" +
                    "You added reviewer fields to the tracker which will be removed, are you sure you want to disable reviews?")) {

                    e.preventDefault()
                }
                else {
                    this.tracker.reviewerFields = []
                }
            }
        },

        addDefaultRole() {
            if (!this.id && this.permissionHolders.length && this.roles.length && !this.tracker.permissions.find(item => item.id === -1)) {
                let defaultRole = this.roles.find(item => item.is_default === 1);
                let group = this.permissionHolders.find(item => parseInt(item.id) === -1);

                if (defaultRole && group) {
                    this.tracker.permissions.push(
                        {
                            ...group,
                            role: defaultRole
                        }
                    );
                }
            }
        },

        addDefaultQuestions() {
            this.questionsList.filter(item => item.default_question).forEach(field => {
                this.tracker.questions.push({
                    field,
                    required: false,
                })
            })
        }
    },
}
</script>

<style scoped>

</style>
