import axios from '@/axios';

const RESOURCE_NAME = '/team';

export default {
    getGroupsIndex(search) {
        return axios.get(`${RESOURCE_NAME}/get-groups-index?search=${search}`)
    },

    getGroupEdit(id) {
        return axios.get(`${RESOURCE_NAME}/get-group-edit?group_id=${id}`)
    },

    deleteGroup(id) {
        return axios.post(`${RESOURCE_NAME}/delete-group?group_id=${id}`)
    },

    saveGroup(id, data) {
        return axios.post(`${RESOURCE_NAME}/save-group?group_id=${id}`, data)
    },

    getUsersList() {
        return axios.get(`${RESOURCE_NAME}/get-users-list`)
    },

    getUsersAndGroupsList() {
        return axios.get(`${RESOURCE_NAME}/get-users-and-groups-list`)
    },

    getPermissionHolders() {
        return axios.get(`${RESOURCE_NAME}/get-permission-holders`)
    },

    getUsersMentionList() {
        return axios.get(`${RESOURCE_NAME}/get-users-mention-list`)
    },

    getUsersIndex(search) {
        return axios.get(`${RESOURCE_NAME}/get-users-index?search=${search}`)
    },

    canAddUser() {
        return axios.get(`${RESOURCE_NAME}/can-add-user`)
    },

    getUserEdit(id) {
        return axios.get(`${RESOURCE_NAME}/get-user-edit?id=${id}`)
    },

    saveUser(id, data) {
        return axios.post(`${RESOURCE_NAME}/save-user?id=${id}`, data)
    },

    deleteUser(id) {
        return axios.post(`${RESOURCE_NAME}/delete-user?id=${id}`)
    },

    getGroupInfo(id) {
        return axios.get(`${RESOURCE_NAME}/get-group-info?group_id=${id}`)
    },

    getGroupUsersIndex(id) {
        return axios.get(`${RESOURCE_NAME}/get-users-index?group_id=${id}`)
    },

    getGroupsList() {
        return axios.get(`${RESOURCE_NAME}/get-groups-list`)
    },

    removeUserFromGroup(groupId, userId) {
        return axios.post(`${RESOURCE_NAME}/remove-user-from-group?group_id=${groupId}&id=${userId}`)
    },

    sendActivationEmail(id) {
        return axios.post(`${RESOURCE_NAME}/send-activation-email?id=${id}`)
    },
}
